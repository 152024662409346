import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import FormRedirectionWrapper from 'components/FormRedirectionWrapper';
import Layout from 'components/Layout';
import AboutYouForm from 'forms/AboutYouForm';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import { navigatePreservingQuote } from 'helpers/navigation';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { usePageTracking } from 'helpers/usePageTracking';
import { useVehicleDetails } from 'state/formData/vehicleDetails';
import { CsHero, CsMeta } from 'types/contentStack';

const STEP = 2;

type AboutYouProps = {
  data: {
    csAboutYou: {
      meta: CsMeta;
      hero: CsHero;
      next_button_text: string;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csAboutYou {
      meta {
        meta_title
      }
      hero {
        heading
        subheading
      }
      next_button_text
    }
  }
`;

const AboutYou: React.FC<AboutYouProps> = ({
  data: {
    csAboutYou: {
      meta,
      hero: { heading, subheading },
      next_button_text,
    },
  },
  location,
}) => {
  const [vehicleDetails] = useVehicleDetails();
  usePageTracking(meta.meta_title, !!vehicleDetails.personalMileage);

  useEffect(() => trackCheckoutEvent(STEP, null, 'Quote and Buy'), []);

  return (
    <LoadQuoteInProgressWrapper location={location}>
      <FormRedirectionWrapper location={location}>
        <Layout meta={meta} quoteAndBuyStep={STEP} pageType="quoteAndBuy">
          <HeroPlain heading={heading} subhead={subheading || undefined} />
          <AboutYouForm
            moveBack={() => navigatePreservingQuote(quoteAndBuyRoutes.aboutYourCar)}
            moveNextText={next_button_text}
            moveNext={() => navigatePreservingQuote(quoteAndBuyRoutes.aboutYourCover)}
          />
        </Layout>
      </FormRedirectionWrapper>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYou;

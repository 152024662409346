import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import SelectInput from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import TypeaheadInput from '@rsa-digital/evo-shared-components/components/Form/TypeaheadInput';
import {
  BooleanField,
  QuestionWithOptionalPlaceholderField,
  QuestionWithPlaceholderField,
  StringField,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import {
  hasLicenceForLessThanTwoYears,
  isOtherLicenceType,
} from 'api/businessLogic/driverLicence';
import { hasGenderlessTitle } from 'api/businessLogic/gender';
import { isEmployed } from 'api/businessLogic/occupation';
import React, { ReactElement } from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import FormFooter from 'components/FormFooter';
import NameInput from 'components/NameInput';
import QuestionField from 'components/QuestionField';
import { FormNavProps } from 'forms/formNavProps';
import {
  trackDropdownSelect,
  trackFieldError,
  trackRadioButtonClick,
  trackSelectOrTypeaheadFocus,
  trackTextInputFocus,
  trackTypeaheadFocusWithDebounce,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { INPUT_REGEX_DRIVING_LICENCE, INPUT_REGEX_NAME } from 'helpers/inputRegexes';
import { DriverDetails, useDriverDetails } from 'state/formData/driverDetails';
import { useQuote } from 'state/quote/quote';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import AddressForm from './AddressForm';
import useAboutYouQuestions, { AboutYouQuestions } from './questions';
import useAboutYouRules from './validation';
import { useSingleEffectOnceConditionTrue } from '../../helpers/useSingleEffectOnceConditionTrue';

const AboutYouForm: React.FC<FormNavProps & { isRenewal?: boolean }> = ({
  moveBack,
  moveNextText,
  moveNext,
  isRenewal,
}) => {
  const questions = useAboutYouQuestions();
  const rules = useAboutYouRules();

  const [driverDetails, updateDriverDetails] = useDriverDetails();
  const { migratedFields } = driverDetails;

  const {
    getError,
    validateOnSubmit,
    showValidation,
    showAllValidationErrors,
  } = useValidation(driverDetails, rules, trackFieldError);

  const quote = useQuote();

  useSingleEffectOnceConditionTrue((): void => {
    showAllValidationErrors();
  }, !!quote);

  const accountDetailsRefData = useReferenceData('accountDetails');
  const driverDetailsRefData = useReferenceData('driverDetails');
  const occupationsRefData = useReferenceData('occupations');
  const policyDetailsRefData = useReferenceData('policyDetails');

  const renderRefDataQuestion = (
    id: QuestionWithPlaceholderField<AboutYouQuestions> & StringField<DriverDetails>,
    options: ReferenceDataOption[] | undefined
  ): ReactElement => (
    <QuestionField question={questions[id]} errorText={getError(id)}>
      <SelectInput
        id={id}
        value={driverDetails[id]}
        options={options ?? []}
        placeholder={questions[id].placeholder}
        onChange={(e) => {
          trackDropdownSelect(id, e.target.value);
          updateDriverDetails({
            [id]: e.target.value,
          });
        }}
        onBlur={() => showValidation(id)}
        onFocus={trackSelectOrTypeaheadFocus(id)}
        loading={!options}
      />
    </QuestionField>
  );

  const renderTypeaheadQuestion = (
    id: QuestionWithOptionalPlaceholderField<AboutYouQuestions> &
      StringField<DriverDetails>,
    options: ReferenceDataOption[] | undefined
  ): ReactElement => {
    const mappedOptions = options?.map((option) => ({
      id: option.value,
      label: option.name,
    }));

    const value = mappedOptions?.find((option) => option.id === driverDetails[id]);

    return (
      <QuestionField question={questions[id]} errorText={getError(id)}>
        <TypeaheadInput
          id={id}
          value={value}
          options={mappedOptions ?? []}
          placeholder={questions[id].placeholder}
          onChange={(option) =>
            updateDriverDetails({
              [id]: option?.id,
            })
          }
          onBlur={() => showValidation(id)}
          onFocus={trackTypeaheadFocusWithDebounce(id)}
        />
      </QuestionField>
    );
  };

  const renderBooleanQuestion = (
    id: Exclude<
      Exclude<BooleanField<DriverDetails>, 'isManualAddress'>,
      'usePreviousAddress'
    >
  ): ReactElement => (
    <QuestionField question={questions[id]} errorText={getError(id)}>
      <BooleanRadioInput
        id={id}
        value={driverDetails[id]}
        onChange={(newValue) => updateDriverDetails({ [id]: newValue })}
      />
    </QuestionField>
  );

  const updateIfMatchRegex = (
    id: StringField<DriverDetails>,
    update: string,
    regex: RegExp
  ): void => {
    if (update.match(regex)) {
      updateDriverDetails({
        [id]: update,
      });
    }
  };

  return (
    <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
      {renderRefDataQuestion('title', accountDetailsRefData?.title)}
      {hasGenderlessTitle(driverDetails) && (
        <QuestionField question={questions.gender} errorText={getError('gender')}>
          <RadioInput
            id="gender"
            value={driverDetails.gender}
            options={accountDetailsRefData?.gender ?? []}
            onChange={(e) => {
              updateDriverDetails({ gender: e.target.value });
              trackRadioButtonClick('gender', e.target.value);
            }}
          />
        </QuestionField>
      )}
      <QuestionField question={questions.firstName} errorText={getError('firstName')}>
        <NameInput
          id="firstName"
          placeholder={questions.firstName.placeholder}
          maxLength={60}
          value={driverDetails.firstName}
          onChange={(e) =>
            updateIfMatchRegex('firstName', e.target.value, INPUT_REGEX_NAME)
          }
          onBlur={() => showValidation('firstName')}
          onFocus={trackTextInputFocus('firstName')}
          disabled={isRenewal}
        />
      </QuestionField>
      <QuestionField question={questions.lastName} errorText={getError('lastName')}>
        <NameInput
          id="lastName"
          placeholder={questions.lastName.placeholder}
          maxLength={60}
          value={driverDetails.lastName}
          onChange={(e) =>
            updateIfMatchRegex('lastName', e.target.value, INPUT_REGEX_NAME)
          }
          onBlur={() => showValidation('lastName')}
          onFocus={trackTextInputFocus('lastName')}
          disabled={isRenewal}
        />
      </QuestionField>
      <QuestionField question={questions.dateOfBirth} errorText={getError('dateOfBirth')}>
        <DateInput
          id="dateOfBirth"
          value={driverDetails.dateOfBirth}
          onChange={(value) =>
            updateDriverDetails({
              dateOfBirth: value,
            })
          }
          onBlur={() => showValidation('dateOfBirth')}
          onFocus={trackTextInputFocus('dateOfBirth')}
          disabled={isRenewal}
        />
      </QuestionField>
      {!migratedFields?.maritalStatusIsMigrated &&
        renderRefDataQuestion('maritalStatus', accountDetailsRefData?.maritalStatus)}
      <AddressForm
        addressDetails={driverDetails}
        updateAddressDetails={updateDriverDetails}
        showValidation={showValidation}
        getError={getError}
      />
      {renderRefDataQuestion(
        'lengthLivedAtAddress',
        accountDetailsRefData?.lengthLivedAtAddress
      )}
      <QuestionField question={questions.email} errorText={getError('email')}>
        <TextInput
          id="email"
          placeholder={questions.email.placeholder}
          maxLength={254}
          value={driverDetails.email}
          onChange={(e) =>
            updateDriverDetails({
              email: e.target.value,
            })
          }
          onBlur={() => showValidation('email')}
          onFocus={trackTextInputFocus('email')}
          disabled={isRenewal}
        />
      </QuestionField>
      <QuestionField
        question={questions.mainTelephone}
        errorText={getError('mainTelephone')}>
        <TextInput
          id="mainTelephone"
          placeholder={questions.mainTelephone.placeholder}
          maxLength={11}
          value={driverDetails.mainTelephone}
          onChange={(e) =>
            updateDriverDetails({
              mainTelephone: e.target.value,
            })
          }
          numbersOnly
          onBlur={() => showValidation('mainTelephone')}
          onFocus={trackTextInputFocus('mainTelephone')}
        />
      </QuestionField>
      {!migratedFields?.livedInUkSinceBirthIndicatorIsMigrated &&
        renderBooleanQuestion('hasLivedInUkSinceBirth')}
      {!migratedFields?.ukResidentSinceIsMigrated &&
        driverDetails.hasLivedInUkSinceBirth === false && (
          <QuestionField
            question={questions.ukResidentSince}
            errorText={getError('ukResidentSince')}>
            <DateInput
              id="ukResidentSince"
              value={driverDetails.ukResidentSince}
              hideDay
              onChange={(value) =>
                updateDriverDetails({
                  ukResidentSince: value,
                })
              }
              onBlur={() => showValidation('ukResidentSince')}
              onFocus={trackSelectOrTypeaheadFocus('ukResidentSince')}
            />
          </QuestionField>
        )}
      {!migratedFields?.isHomeOwnerIsMigrated && renderBooleanQuestion('isHomeowner')}
      {!migratedFields?.childrenUnder16IndicatorIsMigrated &&
        renderBooleanQuestion('hasChildrenUnder16')}
      {!migratedFields?.numberOfChildrenInHomeUnder16IsMigrated &&
        driverDetails.hasChildrenUnder16 &&
        renderRefDataQuestion(
          'numberOfChildrenInHomeUnder16',
          policyDetailsRefData?.numberOfChildrenInHomeUnder16
        )}
      {!migratedFields?.licenceTypeIsMigrated &&
        renderRefDataQuestion('licenceType', driverDetailsRefData?.licenceType)}
      {!migratedFields?.licenceTypeIsMigrated &&
        isOtherLicenceType(driverDetails) &&
        renderRefDataQuestion('otherLicenceType', driverDetailsRefData?.otherLicenceType)}
      {renderRefDataQuestion('licenceHeldYears', driverDetailsRefData?.licenceHeldYears)}
      {hasLicenceForLessThanTwoYears(driverDetails) &&
        renderRefDataQuestion(
          'licenceHeldMonths',
          driverDetailsRefData?.licenceHeldMonths
        )}
      <QuestionField
        question={questions.licenceNumber}
        errorText={getError('licenceNumber')}>
        <TextInput
          id="licenceNumber"
          placeholder={questions.licenceNumber.placeholder}
          maxLength={16}
          value={driverDetails.licenceNumber}
          onChange={(e) =>
            updateIfMatchRegex(
              'licenceNumber',
              e.target.value.toUpperCase(),
              INPUT_REGEX_DRIVING_LICENCE
            )
          }
          onBlur={() => showValidation('licenceNumber')}
          onFocus={trackTextInputFocus('licenceNumber')}
        />
      </QuestionField>
      {renderBooleanQuestion('hasMedicalConditionsOrDisabilities')}
      {driverDetails.hasMedicalConditionsOrDisabilities &&
        renderBooleanQuestion('isMedicalConditionsNotified')}
      {renderRefDataQuestion(
        'numberOfCarsInHousehold',
        policyDetailsRefData?.numberOfCarsInHousehold
      )}
      {!migratedFields?.useOfOtherVehiclesIsMigrated &&
        renderRefDataQuestion(
          'useOfOtherVehicles',
          driverDetailsRefData?.useOfOtherVehicles
        )}
      {renderRefDataQuestion('employmentStatus', driverDetailsRefData?.employmentStatus)}
      {isEmployed(driverDetails) && (
        <>
          {renderTypeaheadQuestion('primaryOccupation', occupationsRefData?.occupation)}
          {renderTypeaheadQuestion(
            'primaryOccupationIndustry',
            occupationsRefData?.occupationIndustry
          )}
          {renderBooleanQuestion('hasSecondaryOccupation')}
          {driverDetails.hasSecondaryOccupation &&
            renderTypeaheadQuestion(
              'secondaryOccupation',
              occupationsRefData?.occupation
            )}
          {driverDetails.hasSecondaryOccupation &&
            renderTypeaheadQuestion(
              'secondaryOccupationIndustry',
              occupationsRefData?.occupationIndustry
            )}
        </>
      )}
      <FormFooter
        contentColumns={{ desktop: 6 }}
        moveNextButton={{
          text: moveNextText,
        }}
        backButton={{
          onClick: moveBack,
        }}
      />
    </form>
  );
};

export default AboutYouForm;

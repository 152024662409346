import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import {
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';

export type AboutYouQuestions = {
  title: QuestionWithPlaceholder;
  gender: Question;
  firstName: QuestionWithOptionalPlaceholder;
  lastName: QuestionWithOptionalPlaceholder;
  dateOfBirth: Question;
  maritalStatus: QuestionWithPlaceholder;
  email: QuestionWithOptionalPlaceholder;
  mainTelephone: QuestionWithOptionalPlaceholder;
  lengthLivedAtAddress: QuestionWithPlaceholder;
  hasLivedInUkSinceBirth: Question;
  ukResidentSince: Question;
  isHomeowner: Question;
  hasChildrenUnder16: Question;
  numberOfChildrenInHomeUnder16: QuestionWithPlaceholder;
  hasMedicalConditionsOrDisabilities: Question;
  isMedicalConditionsNotified: Question;
  numberOfCarsInHousehold: QuestionWithPlaceholder;
  useOfOtherVehicles: QuestionWithPlaceholder;
  licenceType: QuestionWithPlaceholder;
  otherLicenceType: QuestionWithPlaceholder;
  licenceHeldYears: QuestionWithPlaceholder;
  licenceHeldMonths: QuestionWithPlaceholder;
  licenceNumber: QuestionWithOptionalPlaceholder;
  employmentStatus: QuestionWithPlaceholder;
  primaryOccupation: QuestionWithOptionalPlaceholder;
  primaryOccupationIndustry: QuestionWithOptionalPlaceholder;
  hasSecondaryOccupation: Question;
  secondaryOccupation: QuestionWithOptionalPlaceholder;
  secondaryOccupationIndustry: QuestionWithOptionalPlaceholder;
};

type csAboutYouQuestions = {
  csAboutYouPartOne: {
    driver_title: CsQuestionWithPlaceholder;
    gender: CsQuestion;
    first_name: CsQuestionWithPlaceholder;
    last_name: CsQuestionWithPlaceholder;
    date_of_birth: CsQuestion;
    marital_status: CsQuestionWithPlaceholder;
    email: CsQuestionWithPlaceholder;
    main_telephone: CsQuestionWithPlaceholder;
  };
  csAboutYouPartTwo: {
    length_at_address: CsQuestionWithPlaceholder;
    uk_since_birth: CsQuestion;
    uk_resident_date: CsQuestion;
    is_homeowner: CsQuestion;
    has_children_under_sixteen: CsQuestion;
    number_of_children: CsQuestionWithPlaceholder;
    has_notifiable_conditions: CsQuestion;
    conditions_notified: CsQuestion;
    cars_in_household: CsQuestionWithPlaceholder;
    other_vehicle_use: CsQuestionWithPlaceholder;
  };
  csAboutYouLicence: {
    licence_type: CsQuestionWithPlaceholder;
    other_licence_type: CsQuestionWithPlaceholder;
    licence_length_years: CsQuestionWithPlaceholder;
    licence_length_months: CsQuestionWithPlaceholder;
    driving_licence_number: CsQuestionWithPlaceholder;
  };
  csAboutYouOccupation: {
    employment_status: CsQuestionWithPlaceholder;
    main_occupation: CsQuestionWithPlaceholder;
    main_occupation_industry: CsQuestionWithPlaceholder;
    has_second_occupation: CsQuestion;
    secondary_occupation: CsQuestionWithPlaceholder;
    secondary_occupation_industry: CsQuestionWithPlaceholder;
  };
};

const query = graphql`
  query {
    csAboutYouPartOne {
      driver_title {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      gender {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      first_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      last_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      date_of_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      marital_status {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      email {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      main_telephone {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
    }
    csAboutYouPartTwo {
      length_at_address {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      uk_since_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      uk_resident_date {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      is_homeowner {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      has_children_under_sixteen {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      number_of_children {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      has_notifiable_conditions {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      conditions_notified {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      cars_in_household {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      other_vehicle_use {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
    }
    csAboutYouLicence {
      licence_type {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      other_licence_type {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      licence_length_years {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      licence_length_months {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      driving_licence_number {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
    }
    csAboutYouOccupation {
      employment_status {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      main_occupation {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      main_occupation_industry {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      has_second_occupation {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      secondary_occupation {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
      secondary_occupation_industry {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
          placeholder
        }
      }
    }
  }
`;

const useAboutYouQuestions = (): AboutYouQuestions => {
  const csQuestions = useStaticQuery<csAboutYouQuestions>(query);
  const {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
    processQuestionWithPlaceholder,
  } = useQuestionProcessor();

  return {
    title: processQuestionWithPlaceholder(csQuestions.csAboutYouPartOne.driver_title),
    gender: processQuestion(csQuestions.csAboutYouPartOne.gender),
    firstName: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouPartOne.first_name
    ),
    lastName: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouPartOne.last_name
    ),
    dateOfBirth: processQuestion(csQuestions.csAboutYouPartOne.date_of_birth),
    maritalStatus: processQuestionWithPlaceholder(
      csQuestions.csAboutYouPartOne.marital_status
    ),
    email: processQuestionWithOptionalPlaceholder(csQuestions.csAboutYouPartOne.email),
    mainTelephone: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouPartOne.main_telephone
    ),
    lengthLivedAtAddress: processQuestionWithPlaceholder(
      csQuestions.csAboutYouPartTwo.length_at_address
    ),
    hasLivedInUkSinceBirth: processQuestion(csQuestions.csAboutYouPartTwo.uk_since_birth),
    ukResidentSince: processQuestion(csQuestions.csAboutYouPartTwo.uk_resident_date),
    isHomeowner: processQuestion(csQuestions.csAboutYouPartTwo.is_homeowner),
    hasChildrenUnder16: processQuestion(
      csQuestions.csAboutYouPartTwo.has_children_under_sixteen
    ),
    numberOfChildrenInHomeUnder16: processQuestionWithPlaceholder(
      csQuestions.csAboutYouPartTwo.number_of_children
    ),
    hasMedicalConditionsOrDisabilities: processQuestion(
      csQuestions.csAboutYouPartTwo.has_notifiable_conditions
    ),
    isMedicalConditionsNotified: processQuestion(
      csQuestions.csAboutYouPartTwo.conditions_notified
    ),
    numberOfCarsInHousehold: processQuestionWithPlaceholder(
      csQuestions.csAboutYouPartTwo.cars_in_household
    ),
    useOfOtherVehicles: processQuestionWithPlaceholder(
      csQuestions.csAboutYouPartTwo.other_vehicle_use
    ),
    licenceType: processQuestionWithPlaceholder(
      csQuestions.csAboutYouLicence.licence_type
    ),
    otherLicenceType: processQuestionWithPlaceholder(
      csQuestions.csAboutYouLicence.other_licence_type
    ),
    licenceHeldYears: processQuestionWithPlaceholder(
      csQuestions.csAboutYouLicence.licence_length_years
    ),
    licenceHeldMonths: processQuestionWithPlaceholder(
      csQuestions.csAboutYouLicence.licence_length_months
    ),
    licenceNumber: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouLicence.driving_licence_number
    ),
    employmentStatus: processQuestionWithPlaceholder(
      csQuestions.csAboutYouOccupation.employment_status
    ),
    primaryOccupation: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouOccupation.main_occupation
    ),
    primaryOccupationIndustry: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouOccupation.main_occupation_industry
    ),
    hasSecondaryOccupation: processQuestion(
      csQuestions.csAboutYouOccupation.has_second_occupation
    ),
    secondaryOccupation: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouOccupation.secondary_occupation
    ),
    secondaryOccupationIndustry: processQuestionWithOptionalPlaceholder(
      csQuestions.csAboutYouOccupation.secondary_occupation_industry
    ),
  };
};

export default useAboutYouQuestions;
